import React from 'react'
import './style.scss'

import CF7Information from '../../../../Form/CF7/Information'

function CustomBlock({ fields: field }) {

	return (
        <>
            <h2>{field}</h2>
            <CF7Information />
        </>
	)
}

export default CustomBlock