import React, { useState } from 'react'
import './style.scss'

import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { navigate } from "gatsby"


function CF7Information(props) {

	
	const { register, handleSubmit, errors, reset, control } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	// Form activation // thanks
	const [isActive, setActive] = useState(false)
	const [isSent, setSent] = useState(false)

	const typeofeventvalues = [
		{
			value: 'Associate of Science in Nursing',
			label: 'Associate of Science in Nursing',
		},
		{
			value: 'Bachelor of Science in Nursing',
			label: 'Bachelor of Science in Nursing',
		},
		{
			value: 'Master of Science in Nursing',
			label: 'Master of Science in Nursing',
		},
		{
			value: 'Master of Business Administration',
			label: 'Master of Business Administration',
		},
		{
			value: 'Master of Education in Educational Administration',
			label: 'Master of Education in Educational Administration',
		},
		{
			value: 'Diploma Program',
			label: 'Diploma Program',
		}
	]


	const customStyles = {
		// control: (base, state) => ({
		// 	...base,
		// 	boxShadow: "none",
		// }),
		// option: (base, state) => ({
		// 	...base,
		// 	color: state.isSelected ? '#000' : state.isFocused ? '#000' : '#DCBA7B',
		// }),
		// valueContainer: (base) => ({
		// 	...base,
		// 	minHeight: 30,
		// })
	}

	const onClickFillThisForm = (e) => {
		setActive(status => !status)
	}

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
        data.append('your-lname', form.lname)
		data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-typeofdegree', form.typeofdegree?.value)
		
    const url = 'https://cms.mru.edu/wp-json/contact-form-7/v1/contact-forms/758/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						setSent(true)
						setActive(false)
						reset()
            navigate("/thank-you")
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<>
			<div className="badge">
				<div className={`hi ${!isActive && !isSent ? 'active' : ''}`}></div>
				<div className={`thanks ${isSent && !isActive ? 'active' : ''}`}></div>
			</div>

			<div className="form-cut-off">
				<form
					name="contact"
					method="post"
                    id="information-form"
					action="/thanks/"
					onSubmit={handleSubmit(onSubmit)}
					className={`grid-12 uppercase catering border-type-4 ${isActive ? 'active' : ''}`}
				>


					{/* <div className="span-12 text-center fill-this-form animated delay-500" onClick={(e) => onClickFillThisForm(e)}><h3>Fill this form</h3></div> */}

					<div className={`span-12 grid-12 form-fields-wrap gap-3 ${isActive ? 'active' : ''}`}>

						<label  className="span-6 font4 animated delay-500">
							<input  type="text" className="text-left" placeholder="FIRST NAME" name="fname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.name && <p className="small margin-off">your first name is required.</p>}
						</label>
                        <label className="span-6 font4 animated delay-500">
							<input type="text" className="text-left" placeholder="LAST NAME" name="lname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.name && <p className="small margin-off">your last name is required.</p>}
						</label>
						<label className="span-6 font4 animated delay-500">
							<input type="text" className="text-left" placeholder="EMAIL" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
							{errors.email && <p className="small margin-off">a correct email is required.</p>}
						</label>
						<label className="span-6 font4 animated delay-500">
							<input type="text" className="text-left" placeholder="PHONE NUMBER" name="phone" ref={register({required: false, maxLength: 80, message: "error message"})} />
						</label>
                        <p className="span-6 span-12-mobile font4 question">What type of Degree are you interested in pursuing?</p>
						<label className="elevate-z span-6 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="typeofdegree"
									control={control}
									isClearable
									className="font4" 
									placeholder="Type of Degree..."
									//onChange={event => handleOptionChangeSelect(index, event)}
									options={typeofeventvalues.map(val => (
										{ value: val.value, label: val.label }
									))}
									styles={customStyles}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											// text: '#ffffff',
											primary: '#CFA052',
											// primary75: '#EAC582',
											// primary50: '#EAC582',
											// primary25: '#EAC582',
										
											// danger: '#DE350B',
											// dangerLight: '#FFBDAD',
										
											// neutral0: '#2A4B2E',
											// neutral5: '#EAC582',
											// neutral10: 'hsl(0, 0%, 90%)',
											// neutral20: '#EAC582',
											// neutral30: '#ffffff',
											// neutral40: '#668B6B',
											// neutral50: '#D0C8B9',
											// neutral60: '#668B6B',
											neutral70: 'hsl(0, 0%, 30%)',
											// neutral80: '#D0C8B9',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
						

						<div className="btn-wrap text-center animated delay-500">
							<button type="submit">SUBMIT {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
						</div>

						<div className="response-wrap text-center span-12 animated delay-500">
							<p className="message small">{message}</p>
						</div>
					</div>

				</form>
			</div>
		</>
	)
}

export default CF7Information